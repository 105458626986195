import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getTahun(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/tahun', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addTahun(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/tahun', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateTahun(_,{id,data}){
            return new Promise((resolve, reject) => {
                axios.put('/tahun/update/'+ id, data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteTahun(_,id){
            return new Promise((resolve, reject) => {
                axios.delete('/tahun/delete/'+ id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}