import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getUrusan(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/urusan', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addUrusan(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/urusan', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addUrusanTahun(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/urusan/urusan_tahun', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateUrusan(_,{id,data}){
            return new Promise((resolve, reject) => {
                axios.put('/urusan/update/'+ id, data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteUrusan(_,id){
            return new Promise((resolve, reject) => {
                axios.delete('/urusan/delete/'+ id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getUrusanNested(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/urusan/nested', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getUrusanTahun(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/urusan/urusan_tahun', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getReport(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/urusan/report', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}